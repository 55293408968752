"use strict";
$(document).on('turbolinks:load', function () {
  // 新建页面
  if ($('#maintenance-fee-new-wrapper').is(':visible')) {
    const detailField = $('.detail-form').prop('outerHTML')

    $("#add-detail-btn").unbind().on("click", function () {
      const detailCount = $('.detail-form').length
      const newDetailField = detailField.replace(/0/g, detailCount).replace("维修项目",`维修项目${detailCount+1}`);
      $(newDetailField).insertBefore("#add-detail-btn").hide().show('slow');
    });

    $("#remove-detail-btn").unbind().on("click", function () {
      const detailCount = $('.detail-form').length
      if(detailCount > 1) {
        $('.detail-form').last().hide('slow', function(){$(this).remove()})
      }
    })

    const maintenanceCompaniesList = $('#maintenance-companies').data('list')
    if (maintenanceCompaniesList) {
      const maintenanceUnitList = maintenanceCompaniesList.map((company) => {return JSON.parse(company)})
  
      for(let idx of [...Array(maintenanceUnitList.length).keys()]){
        $(`.quick-input-btn[data-index=${idx}]`).text(maintenanceUnitList[idx]['name'])
      }
  
      $(".quick-input-btn").on("click", function(){
        const idx = $(this).data('index')
        $('#maintenance_fee_maintenance_unit').val(maintenanceUnitList[idx]['name'])
        $('#maintenance_fee_maintenance_phone').val(maintenanceUnitList[idx]['phone'])
      })
    }
    

    const maintenanceReasonList = [ 
      {name:'保养维护'}, 
      {name:'电器故障'},
      {name:'补换轮胎'},
      {name:'机械故障'} 
    ]
    for(let index of [...Array(maintenanceReasonList.length).keys()]){
      $(`.reason-quick-input-btn[data-index=${index}]`).text(maintenanceReasonList[index]['name'])
    }
    $(".reason-quick-input-btn").on("click", function(){
      const index = $(this).data('index')
      $('#maintenance_fee_maintenance_reason').val(maintenanceReasonList[index]['name'])
    })
  }

  // 编辑页面
  if ($('#maintenance-fee-edit-wrapper').is(':visible')) {
    const detailField = $('.detail-form').prop('outerHTML')

    $("#add-detail-btn").unbind().on("click", function () {
      const detailCount = $('.detail-form').length
      const newDetailField = detailField.replace(/0/g, detailCount).replace("维修项目",`维修项目${detailCount+1}`);
      
      $(newDetailField).insertBefore("#add-detail-btn").hide().show('slow');
      
      $(`#detail-form-${detailCount}`).children().find('input[type="text"], input[type="number"]').each(function(){
        $(this).attr('value',null);
      });
    });
    $("#remove-detail-btn").unbind().on("click", function () {
      const detailCount = $('.detail-form').length
      if(detailCount > 1) {
        $('.detail-form').last().hide('slow', function(){$(this).remove()})
      }
    })

    const maintenanceCompaniesList = $('#maintenance-companies').data('list')
    if (maintenanceCompaniesList) {
      const maintenanceUnitList = maintenanceCompaniesList.map((company) => {return JSON.parse(company)})
  
      for(let idx of [...Array(maintenanceUnitList.length).keys()]){
        $(`.quick-input-btn[data-index=${idx}]`).text(maintenanceUnitList[idx]['name'])
      }
  
      $(".quick-input-btn").on("click", function(){
        const idx = $(this).data('index')
        $('#maintenance_fee_maintenance_unit').val(maintenanceUnitList[idx]['name'])
        $('#maintenance_fee_maintenance_phone').val(maintenanceUnitList[idx]['phone'])
      })
    }
    

    const maintenanceReasonList = [ 
      {name:'保养维护'}, 
      {name:'电器故障'},
      {name:'补换轮胎'},
      {name:'机械故障'} 
    ]
    for(let index of [...Array(maintenanceReasonList.length).keys()]){
      $(`.reason-quick-input-btn[data-index=${index}]`).text(maintenanceReasonList[index]['name'])
    }
    $(".reason-quick-input-btn").on("click", function(){
      const index = $(this).data('index')
      $('#maintenance_fee_maintenance_reason').val(maintenanceReasonList[index]['name'])
    })
  }
})