"use strict";
$(document).on('turbolinks:load', function () {
  if ($('#inquiry-form-new-wrapper').is(':visible')) {
    
    function checkSubmitBtnEnable(){
      let required = ($('.selector-radio:checked').length > 0) && ($('#inquiry_form_price').val() > 0 )&& ($('#inquiry_form_heat').val() > 0 ) && ($('#inquiry_form_expense').val() > 0 ) && ($('#inquiry_form_freight').val() > 0 )

      if(required){
        $('.submit-btn').prop('disabled', false)
      }else {
        $('.submit-btn').prop('disabled', true)
      }
    }

    $('.selector-radio').on('click', function() {
      const baseInfo = $("#mines-base-info").data('info');
      const mine = baseInfo.find((e)=>{ return e.name === this.value});
      if(mine) {
        $('#inquiry_form_heat').val(mine.heat)
        $('#inquiry_form_expense').val(mine.expense)
        $('#inquiry_form_freight').val(mine.freight)
      }
      checkSubmitBtnEnable()
    })

    $('.form-control').on('change', function() {
      checkSubmitBtnEnable()
    })
  }
})